import * as React from 'react';

import Layout from '../../components/layout';
import SachVideo from '../../images/3Sach-food-video';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  Case study
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  Vietnamese chain of gourmet food shops adopts Odoo in phases
                </h1>
                <p>
                  <a href="https://3sachfood.vn/en_US/">3Sach Food</a> operates
                  ten shops in Ho Chi Minh City, Vietnam which sell gourmet
                  food, hold cooking classes, and rent kitechen space.
                </p>
                <p>
                  The owner was familiar with Odoo and had even tried on her own
                  to use it for online sales. Eventually she located an Odoo
                  partner and arranged for a full implementation.
                </p>
                <p>
                  In{' '}
                  <a href="https://www.youtube.com/watch?v=xoWj4z6dcOo">
                    this 25-minute video
                  </a>{' '}
                  the owner and her partner,{' '}
                  <a href="https://portcities.net/">Port Cities</a>, explain how
                  they planned and executed a phased implementation of Odoo.
                </p>
                <a href="https://www.youtube.com/watch?v=xoWj4z6dcOo">
                  <figure>
                    <SachVideo className="w-full rounded" />
                    <figcaption className="text-center">
                      Cashier at 3Sach Food outlet uses Odoo Point of Sale
                    </figcaption>
                  </figure>
                </a>
                <ul>
                  <li>
                    <strong>Phase 1:</strong> Procurement, inventory management,
                    point of sales, sales & invoicing
                  </li>
                  <li>
                    <strong>Phase 2:</strong> Manufacturing (to cover the
                    operations of the kitchen studios) & accounting
                  </li>
                  <li>
                    <strong>Phase 3:</strong> HR, marketing, and online sales
                  </li>
                </ul>{' '}
                <hr />
                <p>
                  Want to hear more? Contact us for a demo. Email{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a> for
                  details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
